import * as React from "react"
import AppCard from "./appCard";
import * as styles from "./appCards.module.css"

const AppCards = ({apps}) => (
    <div className={styles.appCards}>
        {
            apps.map((app) => {
                return <AppCard key={app.id} app={app}/>
            })
        }
    </div>
)

export default AppCards
