import * as React from "react"
import {graphql, useStaticQuery} from "gatsby"
import {Helmet} from "react-helmet"
import {Link} from "../../.cache/gatsby-browser-entry";
import Layout from "../components/layout";
import Seo from "../components/seo";
import SectionTitle from "../components/sectionTitle";
import AppCards from "../components/appCards";
import * as styles from "./index.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEnvelope, faChevronRight} from "@fortawesome/free-solid-svg-icons"

const IndexPage = () => {
    const {allAppsYaml} = useStaticQuery(
        graphql`
          query {
            allAppsYaml(limit: 8) {
              edges {
                node {
                  id
                  name
                  description
                  image
                  store {
                    appstore
                    googleplay
                  }
                }
              }
            }
           }
    `
    )
    const apps = allAppsYaml.edges.map((e) => e.node)

    return (
        <Layout isTop={true}>
            <Seo/>

            <section id="apps" className={styles.section}>
                <SectionTitle title="配信アプリ"/>
                <AppCards apps={apps}/>
                <div className={styles.allAppsLink}>
                    <Link to={"/apps"}><FontAwesomeIcon icon={faChevronRight}/><span>すべてのアプリ</span></Link>
                </div>
            </section>

            <section id="contact" className={styles.section}>
                <SectionTitle title="コンタクト"/>
                <div className={styles.contactWrapper}>
                    <a className={styles.contactButton} href="mailto:nullhouse0@gmail.com">
                        <FontAwesomeIcon icon={faEnvelope}/><span>Email</span>
                    </a>
                </div>
            </section>
        </Layout>
    )
}

export default IndexPage
