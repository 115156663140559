import * as React from "react"
import * as styles from "./appCard.module.css"

const AppCard = ({app}) => (
    <div className={styles.card}>
        <div className={styles.upper}>
            <picture>
                <source srcSet={`https://storage.googleapis.com/nullhouse-info/apps/${app.image}.webp`}
                        type="image/webp"/>
                <img src={`https://storage.googleapis.com/nullhouse-info/apps/${app.image}.png`}
                     alt={app.name}
                     className={styles.appIcon}
                />
            </picture>
            <div className={styles.appNameAndDescription}>
                <p className={styles.appName}>{app.name}</p>
                <p className={styles.appDescription}
                   dangerouslySetInnerHTML={{__html: app.description.replace(/\n/gm, '<br />')}}/>
            </div>
        </div>
        <div className={styles.storeLinks}>
            {
                app.store.googleplay ?
                    <a className={styles.googlePlay}
                       href={`https://play.google.com/store/apps/details?id=${app.store.googleplay}`}
                       target="_blank"
                       rel="noreferrer">
                        <img
                            src="https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png"
                            width="138"
                            height="53"
                            alt="Google Play"/>
                    </a> : <></>
            }
            {
                app.store.appstore ?
                    <a className={styles.appStore}
                       href={`https://itunes.apple.com/jp/app/itunes-u/id${app.store.appstore}`}
                       target="_blank"
                       rel="noreferrer">
                        <img
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=226x84"
                            width="113"
                            height="42"
                            alt="App Store"/>
                    </a> : <></>
            }
        </div>
    </div>
)

export default AppCard
