import * as React from "react"
import PropTypes from "prop-types"
import * as styles from "./sectionTitle.module.css"

const SectionTitle = ({title, id}) => (
    <div className={styles.wrapper}>
        <h2 id={id} className={styles.title}>{title}</h2>
        <div className={styles.border} />
    </div>
)

SectionTitle.propTypes = {
    title: PropTypes.string,
}

export default SectionTitle
